<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :filterFields="filterFields"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'ApiProxyLog',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'ApiProxyLog',
      tableCaption: 'Журнал вызовов API-прокси',
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Журнал вызовов API-прокси',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'Момент вызова', alias: 'moment', order: 'moment' },
        { text: 'Url вызываемого метода', alias: 'url', order: 'url' },
        { text: 'Переданные данные', alias: 'data', order: 'data' },
        { text: 'Название проекта', alias: 'projectName', order: 'projectName' },
      ],
      filterFields: [
        { text: 'ID', alias: 'id' },
        { text: 'Момент вызова', name: 'moment', alias: 'moment', filterType: 'DateTime' },
        { text: 'Url вызываемого метода', alias: 'url' },
        { text: 'Переданные данные', alias: 'data' },
        { text: 'Название проекта', alias: 'projectName' },
      ],
    };
  },
};
</script>
